.sidebar-logo {
    margin-bottom: 20px !important;

    .ant-menu-title-content {
        margin-left: 7px !important;
        font-family: Rubik;
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        color: #fff;
    }
}

.page-content {
    padding: 24px;
    margin: 0;
    min-height: 280px;
    overflow: auto;
}

.page-breadcrumb {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 16px 0;
}

.dashboard-card {
    width: 100%;
    background: #fff;
}