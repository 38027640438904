.stores-page {
    .stores-table {
        .ant-empty-image {
            svg {
                width: 28px;
            }
        }
    }

    .ant-card-head-title {
        padding: 0 !important;
        white-space: unset !important;
    }
}
