.marketplaces-tab {
    &__marketplace-card {
        width: 100%;
        background: #fff;
        height: 100%;

        .ant-card-body {
            height: 100%;
        }

        &__content {
            height: 100%;
            justify-content: space-between;
        }
    }
}
