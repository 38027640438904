@import './normalize.css';
@import './globals.scss';
@import './ant-custom.scss';

.labelNoDots {
    label {
        &::after {
            content: none !important;
            color: greenyellow;
        }
    }
}

* {
    font-synthesis: none !important; // fix font-weight issue in safari
}

[id^='react-joyride-step-'] {
    button {
        outline: 0;
        transition: all .3s;
        &:hover {
            background-color: #ff8b52 !important;
        }
    }
}

.blurred-link-block {
    pointer-events: none;
    filter: blur(3px);
}
