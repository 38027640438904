$pickerCellDim: 16px;

.ant-picker-content {
    th,
    td {
        line-height: $pickerCellDim !important;
        width: $pickerCellDim !important;
        min-width: $pickerCellDim !important;
        height: $pickerCellDim !important;
    }
}

.ant-table-cell-row-hover {
    cursor: pointer;
}

.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-column-sort {
  background-color: inherit;
}

@media (max-width: 992px) {
    .ant-picker-panel:last-child .ant-picker-date-panel {
      display: none !important;
    }
  
    .ant-picker-panel:first-child
      .ant-picker-date-panel
      .ant-picker-header-next-btn,
    .ant-picker-panel:first-child
      .ant-picker-date-panel
      .ant-picker-header-super-next-btn {
      visibility: initial !important;
    }
  
    .ant-picker-panel.ant-picker-range-wrapper {
      min-width: 288px !important;
    }
  
    .ant-picker-presets {
      display: none !important;
    }
  }

  @media (max-width: 460px) {
   .ant-picker-dropdown {
    transform: scale(0.8);
   }
  }