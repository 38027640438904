
.welcome-description {
    padding: 24px 20px;
    gap: 8px;
}

.welcome-description-title {
    margin: 0;
    opacity: 0.85;
}

.welcome-header {
    background: #fff;
    width: 100%;
    height: 60px;
}

