.product-campaign-table {
    &__favorite {
        background-color: #fffbe6;

        .ant-table-cell-fix-right-first {
            background-color: #fffbe6;
        }
    }

    &__product-tracking-link {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
