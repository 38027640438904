.sidebar-logo {
    height: auto !important;
    display: flex !important;
    align-items: center;
    margin-top: 22px !important;
    margin-bottom: 12px !important;

    .ant-menu-title-content {
        margin-inline-start: 16px !important;
        font-family: Rubik;
        font-size: 15px;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
    }
}

.sidebar-menu {
    .ant-menu-item {
        height: 44px;
        line-height: 44px;
    }
}

.hide-title-content {
    .ant-menu-title-content {
        margin-inline-start: unset !important;
    }
}