.product-table {
    margin: 24px 0;
}

.products__drawer {
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.product-details {
    &__wrap {
        overflow-y: auto;
    }
    &__title {
        margin-top: 0;
    }
    &__divider {
        margin: 8px 0;
    }
    &__details {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 8px 10px;
    }
    &__description {
        justify-self: flex-end;
        margin: 0;
    }
}
