.store-details {
    &__list-of-marketplaces {
        .ant-list-item {
            padding-inline: 0;
        }
        .ant-row {
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }
        &.ant-list {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
        }

        .marketplace-title {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 75px;
            white-space: nowrap;
            margin-left: 12px;
        }
    }
}
