.add-edit-source__form {
    .ant-form-item-row {
        column-gap: 8px;

        .ant-form-item-label {
            width: 220px;
            text-align: left;

            label {
                white-space: pre-wrap;
                min-height: 40px;
                height: auto;
            }
        }
    }

    .action-buttons-block {
        margin-top: 12px;
        column-gap: 24px;
        row-gap: 8px;

        button {
            transition: unset;
        }
    }
}

.source-verification-block {
    &__list {
        color: var(--ant-color-text-description);

        li:not(:first-of-type) {
            margin-top: 12px;
        }

        .copy-card {
            margin-top: 8px;
        }
    }
}
