.page-content {
    padding: 24px;
    margin: 0;
    min-height: 280px;
    overflow: auto;
}

.page-breadcrumb {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin: 16px 0;
}

.dashboard-card {
    width: 100%;
    background: #fff;
}

.dashboard-card-inner {
    width: 100%;
    background: #fff;
}