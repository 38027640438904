.mp-avatar {
	&_disconnected {
		position: relative;
		filter: grayscale(1);
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			opacity: .5;
			background: #fff;
		}
	}
}
