@font-face {
    font-family: 'manrope';
    src: url('../fonts/Manrope-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik/Rubik-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Italic';
    src: url('../fonts/Rubik/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Mono Italic';
    src: url('../fonts/Roboto_Mono/RobotoMono-Italic-VariableFont_wght.ttf') format('truetype');
    font-style: italic;
}