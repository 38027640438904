.copy-value-card {
    height: 40px;
    width: calc(100% - 50px);
    .ant-card-body {
        padding: 8px !important;
        overflow: hidden;
        width: 100%;

        span {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: calc(100%)
        }
    }
}
