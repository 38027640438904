.page-header {
    padding: 0;
    background-color: #fff;
    height: 60px;
    line-height: 60px;

    &__mobile {
        padding: 0;
        height: 60px;
        line-height: 60px;
    }
}

.hide-title-content {
    .ant-menu-title-content {
        margin-inline-start: unset !important;
    }
}

.header-mobile-menu {
    width: 100%;
    justify-content: flex-end;

    .anticon {
        min-width: 40px !important;

        svg {
            display: block;
            margin: 0 auto;
        }
    }

    .logo-menu-item {
        margin: auto;
        display: flex;

        .ant-menu-title-content {
            font-size: 15px;
            font-weight: 700;
            color: #fff;
            margin-inline-start: unset !important;
            margin-left: 6px !important;
        }
    }
}

.mobile-header-notifications-popover {
    top: 59px !important;
    left: 0 !important;
}

.page-menu-popup {
    top: 51px !important;
    left: 0 !important;

    .ant-menu-sub {
        width: 100vw;
        border-radius: 0 0 12px 12px;

        .ant-menu-item {
            height: 44px;
        }
    }
}

.desktop-header-menu {
    .anticon {
        min-width: 40px !important;

        svg {
            display: block;
            margin: 0 auto;
        }
    }
}