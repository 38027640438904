.welcome-role-card {
    width: 100%;
    max-width: 350px;
    background: #fff;
    text-align: center;
    padding: 0;
    border-radius: 12px;
}

.welcome-role-card-image {
    padding: 20px 20px 0px;
}