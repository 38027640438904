.sources-table {
    &__drawer {
        .ant-drawer-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .text-multiline {
        span {
            white-space: pre-wrap;
            word-break: break-all;
            display: block;
        }

        a {
            span {
                text-align: left;
            }
        }
    }
}

.source-details {
    &__wrap {
        overflow-y: auto;
    }
    &__title {
        margin-top: 0;
    }
    &__divider {
        margin: 8px 0;
    }
    &__details {
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 8px 10px;
    }
    &__description {
        justify-self: flex-end;
        margin: 0;
    }
    &__comment-card {
        .ant-card-body {
            padding: 8px !important;
        }
    }
}
