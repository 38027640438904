.products-table,
.campaigns-table {
    .ant-empty-image {
        svg {
            width: 28px;
        }
    }
}

.campaigns-tab {
    &__legal-info-alert {
        width: 100%;
        align-items: center;
    }
}
